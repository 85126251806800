<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      fail_: false,
      errormessage: "",
    };
  },
  created() {},
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  methods: {
    reset_login() {
      this.$store
        .dispatch("authorize_expired")
        .then(this.login())
        .catch((error) => {
          console.log(error);
        });
    },
    async login() {
      console.log("this.$route.query.accesstoken",this.$route.query.accesstoken);
      console.log("this.$route.query.biz",this.$route.query.biz_id);
      //เช็คว่ามี accesstoken มั้ย
      if (!this.$route.query.accesstoken) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!"        
      } else {
        let _accesstoken = this.$route.query.accesstoken;
        let payload ;

        if(this.$route.query.biz_id){
          let business_id = this.$route.query.biz_id;
          console.log("business_id",business_id);
          payload = {
            accesstoken: _accesstoken,
            biz_id: business_id
          };
        }else{
          payload = {
            accesstoken: _accesstoken,
          };
        }
         
        //localStorage.clear();
        // let encodetoken = window.btoa(_accesstoken)
        let encodetoken = _accesstoken;
        // localStorage.setItem("token", encodetoken);
        // localStorage.setItem("token", _accesstoken);
        // ไม่ต้องสนใจเพราะว่า มีการ set token ใหม่ในหน้า Action authorize_login_oneplatform
        VueCookies.set('token', _accesstoken);
        VueCookies.set('token_auth', true);

        // 1 ยิง API getprofile
        let check_auth = await this.$store.dispatch("authorize_login_oneplatform", payload);
        // console.log("check_auth.status",check_auth.status);
        // console.log("check_auth.data.biz",check_auth.data.biz);
        // console.log("check_auth.status",check_auth.status);

        if (check_auth.status === "Access Granted") {
          // ส่ง event ไปยัง Google Analytics
          this.$gtag.event('login', {
            event_category: "oneplatform",
          });

          VueCookies.set("oneplatfrom",true)
          console.log("check_auth",check_auth);
          if(check_auth.data.biz.length !== 0){
            let payload_ = {
            taxid: check_auth.data.biz[0].id_card_num,
            accesstoken: check_auth.data.accesstoken,
            biz_id: check_auth.data.biz[0].id,
            branch_no: check_auth.data.biz[0].id.branch_no,
          };
            let check_auth_biz = await this.$store.dispatch("switchaccount_business", payload_);
            if (check_auth_biz.status === "Business Access Granted") {
              VueCookies.set("oneplatfrom",true)
              VueCookies.set("checklogin",true)
              this.$router.push({ name: "directory", params: { id: this.dataAccountActive.business_info.my_folder_id } });
            }else if(check_auth_biz.status === "Request to OneID was problem"){
              this.fail_ = true;
              this.errormessage = "เซสชั่นหมดอายุ"
            }else{
              this.fail_ = true;
              this.errormessage = "ไม่สามารถ login ได้ !!"
            }
          }else{
            VueCookies.set("checklogin",true)
            VueCookies.set("check_citizen_oneplat",true)
            this.$router.push("/mydrive");
          }
          // this.$router.push({path:'/mydrive'});
        } else if(check_auth.status === "Request to OneID was problem"){
          this.fail_ = true;
          this.errormessage = "เซสชั่นหมดอายุ"
        }else{
          this.fail_ = true;
          this.errormessage = "ไม่สามารถ login ได้ !!"
        }
        // console.log("this.fail_",this.fail_ );
      }
    }
  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    this.reset_login();
  }
}
</script>
